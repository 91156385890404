// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-gear-js": () => import("./../../../src/pages/gear.js" /* webpackChunkName: "component---src-pages-gear-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-staff-js": () => import("./../../../src/pages/staff.js" /* webpackChunkName: "component---src-pages-staff-js" */),
  "component---src-pages-twanb-js": () => import("./../../../src/pages/twanb.js" /* webpackChunkName: "component---src-pages-twanb-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

