import React from 'react'

import footerIcon from '../img/wipe.png'

const Footer = () => (

  <footer>

    <div className="content-block">
        <h1>We're Not Bungie</h1>
        <p className="hand-written">We're really not.</p>
        <p>This is a collection of ever-so-slightly incorrect and nonsensical weekly updates, equipment descriptions, and general lore from the universe of Destiny. Imagine Cayde spilled some spicy ramen on Rasputin's terminal, and now you're sorting through the salvaged files.</p>
        <p>The text itself comes from the hard work and creativity of <a href="https://twitter.com/ChrisDAnimation" rel="noopener noreferrer" target="_blank">Content Chris</a>, leveraging the <a href="http://botnik.org/apps/" rel="noopener noreferrer" target="_blank">Botnik predictive writer</a>. The site is the inevitable result of <a href="https://landtiser.com" rel="noopener noreferrer" target="_blank">Code Chris</a> having too much free time and a love of web design and development.</p>
    </div>

      <div className="footer-divider">
        <div className="footer-border"></div>
        <img src={footerIcon} alt="The Footer Consumes You" />
        <div className="footer-border"></div>
      </div>
      <p>&#169; {(new Date().getFullYear())} - <a className="hand-written" href="https://twitter.com/ChrisDAnimation" rel="noopener noreferrer" target="_blank">Content Chris</a> & <a className="hand-written" href="https://landtiser.com" rel="noopener noreferrer" target="_blank">Code Chris</a></p> 
  
  </footer>

  
)

export default Footer