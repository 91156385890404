import React from 'react'
import PropTypes from 'prop-types'

import GoTrue from 'gotrue-js'
//import invite from '../components/invite'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import './styles.css'

if (typeof document !== `undefined`) {
  // Find out if we're on localhost
  const isLocal = document.location.host.split(':').shift() === 'localhost'

  // Instantiate the library, passing in the live API URL
  // and whether it should save cookies
  const auth = new GoTrue({
    APIUrl: 'https://vigilant-wiles-f70056.netlify.com/.netlify/identity',
    setCookie: !isLocal
  });

  // Get the current user object, or null if no-ones logged in
  const user = auth.currentUser()

  // Log it for next time around
  //console.log(user)
}

const TemplateWrapper = ({ children }) => (
  <section className="contentSection">
    <Navbar />
    <div className="page-content">{children}</div>
    <Footer />
    <form id="identity-form-confirm" className="identity-hide" method="POST" action="/">
      <h2>Finalize Account</h2>
      <label htmlFor="password">Create a Password:</label>
      <input type="password" id="password" name="password" required></input>
      <div className="line-break"></div>
      <input type="submit" id="password-submit" value="Set up account"></input>
    </form>

  </section>
)



TemplateWrapper.propTypes = {
  children: PropTypes.func,
}

export default TemplateWrapper