import React from 'react'
import Link from 'gatsby-link'

import logo from '../img/logo.svg'
import twanbIcon from '../img/twanb-icon.png'
import loreIcon from '../img/lore-icon.png'
import staffIcon from '../img/staff-icon.png'

const Navbar = () => (
  <nav className="navbar">
      <div className="navbar-brand">
        <Link to="/" className="navbar-item">
            <img src={logo} alt="Not Bungie" className="logo" />
        </Link>
      </div>
      <div className="navbar-start">
        <Link className="navbar-item" to="/twanb">
            <img src={twanbIcon} alt="This Week at Not Bungie" className="navbar-icon" />
            TWaNB
        </Link>
        <Link className="navbar-item" to="/gear">
            <img src={loreIcon} alt="Questionable Log of Gear" className="navbar-icon" />
            GEAR
        </Link>
        <Link className="navbar-item" to="/staff">
            <img src={staffIcon} alt="Questionable Employees" className="navbar-icon" />
            STAFF
        </Link>
      </div>
      <div className="navbar-end">
        
      </div>
  </nav>
)

export default Navbar